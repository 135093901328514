import React, { useEffect, useRef } from 'react';

import { graphql } from 'gatsby';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import VisuelContent from '../../../components/ContentType/VisuelComponent/VisuelComponent';
import Seo from '../../../components/Seo/Seo';
import useMedias from '../../../hooks/useMedias';
import Layout from '../../../Layout';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';

const PageCookie = ({ data, pageContext }) => {
  const { getImage } = useMedias();

  const cookieRef = useRef(null);
  const { locale } = pageContext;
  const imagesArray = data?.allImages?.edges;
  const pageData = data?.footerData?.edges[0]?.node;
  const metaTags = pageData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });
  
  useEffect(() => {
    //setDataCulture(locale.toUpperCase())
    let script = document.createElement('script');
    script.src = `https://consent.cookiebot.com/${process.env.COOKIEBOT_KEY}/cd.js`;
    script.setAttribute('data-culture', locale.toUpperCase());
    script.id = 'CookieDeclaration';
    script.async = true;
    cookieRef.current.innerHTML = '';
    cookieRef.current.appendChild(script);
  }, [locale]);

  return (
    <Layout>
        <Seo
          title={pageData?.field_metatag?.title ? pageData?.field_metatag?.title : metaTitle}
          description={pageData?.field_metatag?.description ? pageData?.field_metatag?.description : metaDesc}

        />
      <div className="page_template">
        <div className="wrapper_page">
          <Breadcrumb
            page_banner={false}
            data={{
              currentPage: { title: pageData?.title, url: '' },
              locale: locale,
            }}
          />
          <TitlePage
            color="color_dark_bleu"
            title={pageData?.title}
            description=""
          />
        </div>
        <section className="section_content">
          {pageData?.relationships?.field_image?.drupal_internal__mid && (
            <VisuelContent
              visuel={
                getImage(
                  imagesArray,
                  pageData?.relationships?.field_image?.drupal_internal__mid
                )?.url
              }
            />
          )}
          <div
            className="wrapper_page_xs"
            dangerouslySetInnerHTML={{ __html: pageData?.body?.processed }}
          ></div>
        </section>
        {/* {% if cookie and parameters is defined and parameters["cookiebot_key"] is defined %} */}
        <section className="section_content" id="cookie">
          <div className="wrapper_page_xs">
            {process.env.COOKIEBOT_KEY && (
              <div className="cookietable" ref={cookieRef}></div>
            )}
          </div>
        </section>
      </div>
    </Layout>
  );
};
//                <script id="CookieDeclaration" data-culture={dataCulture} src={`https://consent.cookiebot.com/${process.env.COOKIEBOT_KEY}/cd.js`} type="text/javascript" async></script>

export const query = graphql`
  query pageCookieTemplateQuery(
    $locale: String!
    $slug: String!
    $imageID: [Int!]
  ) {
    footerData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          title
          status
          field_type
          langcode
          body {
            processed
          }
          field_metatag{
            title
            description
          }
          metatag {
            attributes {
              content
              name
            }
          }
          path {
            alias
          }
          relationships {
            field_image {
              drupal_internal__mid
            }
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: { langcode: { eq: "fr" }, drupal_internal__mid: { in: $imageID } }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel
                gatsby_profile_large
                gatsby_profile_thumbnail
                webp
              }
            }
          }
          langcode
        }
      }
    }
  }
`;

export default PageCookie;
